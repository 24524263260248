import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { ErrorService } from '@app/services/common/errorService';
import { StatisticsService } from '@app/services/common/statisticsService';
import { IMuniAGAZone, SubEntityService } from '@app/services/common/subEntityService';
import { CompanySettings, SubEntity } from '@uni-entities';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { FieldType, UniFieldLayout, UniFormModule } from '@uni-framework/ui/uniform';
import { ConfirmActions, UniModalService } from '@uni-framework/uni-modal';
import { ToastService, ToastType } from '@uni-framework/uniToast/toastService';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { THEMES, theme } from 'src/themes/theme';
import { EditSubEntityAgaZoneModal } from '../../editSubEntityAgaZoneModal/editSubEntityAgaZoneModal';

@Component({
    selector: 'uni-sub-entity-settings',
    templateUrl: './sub-entity-settings.component.html',
    styleUrls: ['./sub-entity-settings.component.sass'],
    imports: [CommonModule, UniFormModule, DesignSystemModule, UniFrameworkModule],
    providers: [StatisticsService, SubEntityService, ErrorService, CompanySettingsService],
    standalone: true,
})
export class SubEntitySettingsComponent implements OnInit {
    @Output() step: EventEmitter<number> = new EventEmitter();
    @Output() cancel: EventEmitter<boolean> = new EventEmitter();

    subEntities: SubEntity[] = [];
    fields: UniFieldLayout[] = [];
    selectedSubEntity: SubEntity;
    hasChanges: boolean;
    busy: boolean;
    canCreateNewSubEntities: boolean;
    hasNewUnsaved: boolean;
    companySettings: CompanySettings;
    isPensionCompany: boolean;

    private componentCache: Map<string, any> = new Map<string, any>();

    constructor(
        private statisticsService: StatisticsService,
        private subEntityService: SubEntityService,
        private errorService: ErrorService,
        private companySettingsService: CompanySettingsService,
        private router: Router,
        private modalService: UniModalService,
        private toastSerive: ToastService,
    ) {}

    ngOnInit(): void {
        this.setSubEntities();
    }

    change(event: any) {
        let obs$: Observable<any>;
        this.selectedSubEntity['_dirty'] = true;
        this.hasChanges = true;

        if (event.AgaRule && this.selectedSubEntity.freeAmount === null && event.AgaRule.currentValue === 1) {
            this.selectedSubEntity.freeAmount = 500000;
        }

        if (event.MunicipalityNo) {
            obs$ = this.statisticsService
                .GetAllUnwrapped(
                    `model=AGAZone` +
                        `&select=ZoneName as ZoneName,ID as ID,zo.MunicipalityNo as MunicipalityNo` +
                        `&join=AGAZone.ID on MunicipalAGAZone.ZoneID as zo` +
                        `&filter=zo.ID eq ${event.MunicipalityNo.currentValue}` +
                        `&top=1`,
                )
                .pipe(
                    tap((zone: any) => {
                        if (!this.selectedSubEntity.ID) {
                            this.selectedSubEntity.AgaZone = zone[0].ID;
                        }
                        this.selectedSubEntity.MunicipalityNo = zone[0].MunicipalityNo;
                    }),
                );
        }

        if (obs$) {
            obs$.subscribe({
                next: () => (this.selectedSubEntity = { ...this.selectedSubEntity }),
                error: (error) => this.errorService.handle(error),
            });
        }
    }

    save(): void {
        const dirtySubEnteties = this.subEntities.filter((x) => x['_dirty']);
        const hasOrgNumbers = dirtySubEnteties.some((x) => !x.OrgNumber);
        const hasNames = dirtySubEnteties.some((x) => !x.BusinessRelationInfo?.Name);

        if (hasOrgNumbers || hasNames) {
            this.toastSerive.addToast(
                'En eller eller flere underenheter mangler navn eller underenhetsnummer',
                ToastType.warn,
                5,
            );
            return;
        }

        this.busy = true;
        this.subEntityService
            .saveAll(dirtySubEnteties)
            .subscribe({
                next: (subEntities) => {
                    subEntities.forEach((subEntity) => (subEntity['_guid'] = this.subEntityService.getNewGuid()));
                    this.subEntities = this.subEntities
                        .filter((x) => !x['_dirty'])
                        .concat(subEntities)
                        .sort((a, b) => a.ID - b.ID);
                    this.hasChanges = false;
                    this.hasNewUnsaved = false;
                    this.selectedSubEntity = this.subEntities.find(
                        (x) => x.OrgNumber === this.selectedSubEntity.OrgNumber,
                    );
                    this.hasNewUnsaved = false;
                    this.setFormLayout();
                },
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    setSelectedSubEntity(subEntity: SubEntity) {
        this.selectedSubEntity = this.subEntities.find((x) => x['_guid'] === subEntity['_guid']);
        this.setFormLayout();
    }

    importSubEntities(): void {
        this.busy = true;

        this.subEntityService
            .getFromEnhetsRegister(this.companySettings.OrganizationNumber)
            .pipe(switchMap((subEntities) => this.editZonesIfNeeded(subEntities)))
            .subscribe({
                next: (subEntities: SubEntity[]) => {
                    subEntities.forEach((x) => {
                        x['_dirty'] = true;
                        x['_guid'] = this.subEntityService.getNewGuid();
                    });
                    this.hasChanges = true;
                    this.subEntities = subEntities;
                    this.selectedSubEntity = this.subEntities[0];
                },
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    addSubEntity(): void {
        this.canCreateNewSubEntities = true;
        this.hasNewUnsaved = true;
        this.subEntities.push(<any>{
            _guid: this.subEntityService.getNewGuid(),
            _dirty: true,
        });
        this.hasChanges = true;
        this.selectedSubEntity = this.subEntities[this.subEntities.length - 1];
        this.setFormLayout();
    }

    remove(subEntity: any): void {
        if (subEntity.ID) {
            this.busy = true;
            this.subEntityService
                .Remove(subEntity.ID)
                .subscribe({
                    next: () => {
                        this.filterAndSetSelectedSubEntity(subEntity);
                    },
                    error: (error) => this.errorService.handle(error),
                })
                .add(() => (this.busy = false));
        } else {
            this.filterAndSetSelectedSubEntity(subEntity);
        }
    }

    navigateToCompanySettings(): void {
        this.router.navigate(['settings', 'company']).then(() => this.cancel.emit(true));
    }

    navigate(step: number): void {
        if (this.subEntities.some((x) => x['_dirty'])) {
            this.openUnsavedChangesModal().subscribe({
                next: (action: ConfirmActions) => {
                    if (action === ConfirmActions.ACCEPT) {
                        this.step.emit(step);
                    }
                },
            });
        } else {
            this.step.emit(step);
        }
    }

    close(): void {
        if (this.subEntities.some((x) => x['_dirty'])) {
            this.openUnsavedChangesModal().subscribe({
                next: (action: ConfirmActions) => {
                    if (action === ConfirmActions.ACCEPT) {
                        this.cancel.emit(true);
                    }
                },
            });
        } else {
            this.cancel.emit(true);
        }
    }

    private filterAndSetSelectedSubEntity(subEntity): void {
        this.subEntities = this.subEntities.filter((x) => x['_guid'] !== subEntity['_guid']);

        if (!this.subEntities.length) {
            this.addSubEntity();
        }

        if (this.selectedSubEntity['_guid'] === subEntity['_guid']) {
            this.selectedSubEntity = this.subEntities[0];
            this.setFormLayout();
        }
    }

    private openUnsavedChangesModal(): Observable<ConfirmActions> {
        return this.modalService.confirm({
            header: 'Ulagrede endringer',
            message: 'Du har endringer som ikke er lagret, disse vil bli forkastet.',
            buttonLabels: {
                accept: 'Forkast endringer og fortsett',
                cancel: 'Avbryt',
            },
        }).onClose;
    }

    private get(query: string): Observable<any[]> {
        const cashedData = this.componentCache.get(query);
        if (cashedData) {
            return of(cashedData);
        }
        return this.statisticsService.GetAllUnwrapped(query).pipe(
            tap((data) => {
                this.componentCache.set(query, data);
            }),
        );
    }

    private setSubEntities(): void {
        this.busy = true;
        forkJoin([
            this.companySettingsService.getCompanySettings(),
            this.subEntityService.GetAll('filter=isnull(SuperiorOrganizationID, 0) ne 0', [
                'BusinessRelationInfo.InvoiceAddress',
            ]),
        ])
            .subscribe({
                next: ([settings, subEntities]: [CompanySettings, SubEntity[]]) => {
                    this.companySettings = settings;
                    this.isPensionCompany = settings.CompanyTypeID === 30;
                    subEntities.forEach((x) => (x['_guid'] = this.subEntityService.getNewGuid()));
                    this.subEntities = subEntities;
                    this.selectedSubEntity = this.selectedSubEntity ?? this.subEntities[0];
                    this.setFormLayout();
                },
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    editZonesIfNeeded(subEntities: SubEntity[]): Observable<SubEntity[]> {
        return this.getZonesOnSubEntities(subEntities).pipe(
            switchMap((muniZones) => {
                if (
                    subEntities.some(
                        (sub) => muniZones.filter((zone) => zone.MunicipalityNo === sub.MunicipalityNo).length > 1,
                    )
                ) {
                    return <Observable<SubEntity[]>>this.modalService.open(EditSubEntityAgaZoneModal, {
                        data: {
                            subEntities: subEntities,
                            municipalAgaZones: muniZones,
                        },
                        closeOnClickOutside: false,
                        closeOnEscape: false,
                    }).onClose;
                }
                return of(subEntities);
            }),
        );
    }

    getZonesOnSubEntities(subEntities: SubEntity[]): Observable<IMuniAGAZone[]> {
        if (!subEntities.some((sub) => !!sub.MunicipalityNo)) {
            return of([]);
        }
        return this.statisticsService.GetAllUnwrapped(
            'Select=ZoneName as ZoneName,ID as ZoneID,' +
                'Municipal.MunicipalityNo as MunicipalityNo,Municipal.MunicipalityName as MunicipalityName&' +
                `Model=AGAZone&` +
                `Filter=${subEntities
                    .filter((sub) => !!sub.MunicipalityNo)
                    .map((sub) => `municipalsOnZone.MunicipalityNo eq ${sub.MunicipalityNo}`)
                    .join(' or ')}&` +
                `Join=MunicipalAGAZone.MunicipalityNo eq Municipal.MunicipalityNo as Municipal&` +
                `Expand=municipalsOnZone`,
        );
    }

    private setFormLayout(): void {
        this.fields = [
            <UniFieldLayout>{
                Property: 'BusinessRelationInfo.Name',
                Label: 'Navn på underenhet',
                Legend: 'Kontaktinfo',
                Classes: 'half-width',
                Required: true,
            },
            <UniFieldLayout>{
                Property: 'OrgNumber',
                Label: 'Orgnr for underenhet',
                Classes: 'half-width',
                Required: true,
            },
            <UniFieldLayout>{
                Property: 'MunicipalityNo',
                FieldType: FieldType.AUTOCOMPLETE,
                Label: 'Kommunenummer',
                Options: {
                    template: (obj) =>
                        `${obj.MunicipalityNo} - ${obj.MunicipalityName} ${obj.ZoneName ? ' - ' + obj.ZoneName : ''}`,
                    getDefaultData: () =>
                        this.selectedSubEntity?.MunicipalityNo
                            ? this.get(
                                  `model=Municipal&select=MunicipalityNo as MunicipalityNo,MunicipalityName as MunicipalityName&filter=MunicipalityNo eq '${this.selectedSubEntity.MunicipalityNo}'&top=1`,
                              )
                            : of(),
                    search: (query: string) =>
                        this.statisticsService.GetAllUnwrapped(
                            `select=MunicipalityNo as MunicipalityNo,ZoneID as ZoneID,AGAZone.ZoneName as ZoneName,Municipal.MunicipalityName as MunicipalityName,ID as ID` +
                                `&model=MunicipalAGAZone` +
                                `&join=MunicipalAGAZone.MunicipalityNo eq Municipal.MunicipalityNo as Municipal and MunicipalAGAZone.ZoneID eq AGAZone.ID as AGAZone` +
                                `&filter=Municipal.Retired eq 0 and ( ( startswith(MunicipalityNo,'${query}') or contains(Municipal.MunicipalityName,'${query}') or contains(AGAZone.ZoneName,'${query}') ) )` +
                                `&top=50`,
                        ),
                    displayProperty: 'ID',
                    valueProperty: 'ID',
                    debounceTime: 200,
                },
            },
            <UniFieldLayout>{
                Property: 'BusinessRelationInfo.InvoiceAddress.AddressLine1',
                Label: 'Gateadresse',
                Classes: 'half-width',
            },
            <UniFieldLayout>{
                Property: 'BusinessRelationInfo.InvoiceAddress.PostalCode',
                FieldType: FieldType.AUTOCOMPLETE,
                Label: 'Postnummer',
                Classes: 'half-width',
                Options: {
                    template: (obj) => (obj && obj.Code ? `${obj.Code} - ${obj.City}` : ''),
                    getDefaultData: () =>
                        this.selectedSubEntity.BusinessRelationInfo?.InvoiceAddress?.PostalCode
                            ? this.get(
                                  `model=PostalCode&select=Code as Code,City as City&filter=Code eq ${this.selectedSubEntity.BusinessRelationInfo.InvoiceAddress.PostalCode}&top=1`,
                              )
                            : of(),
                    search: (query: string) =>
                        this.statisticsService.GetAllUnwrapped(
                            `model=PostalCode&select=Code as Code,City as City&filter=startswith(Code,'${query}') or contains(City, '${query}')&top=50`,
                        ),
                    displayProperty: 'Code',
                    valueProperty: 'Code',
                    debounceTime: 200,
                },
            },

            <UniFieldLayout>{
                Property: 'AgaZone',
                FieldType: FieldType.AUTOCOMPLETE,
                Label: 'Sone',
                Classes: 'half-width',
                Options: {
                    template: (obj) => (obj && obj.ZoneName ? `${obj.ZoneName}` : ''),
                    getDefaultData: () =>
                        this.selectedSubEntity.AgaZone
                            ? this.get(
                                  `model=AGAZone&select=ID as ID,ZoneName as ZoneName&filter=ID eq ${this.selectedSubEntity.AgaZone}&top=1`,
                              )
                            : of(),
                    search: (query: string) =>
                        this.statisticsService.GetAllUnwrapped(
                            `model=AGAZone&select=ID as ID,ZoneName as ZoneName&filter=contains(ZoneName,'${query}')&top=50`,
                        ),
                    displayProperty: 'ID',
                    valueProperty: 'ID',
                    debounceTime: 200,
                },
                ReadOnly: !!this.selectedSubEntity?.ID,
            },
            <UniFieldLayout>{
                Property: 'freeAmount',
                FieldType: FieldType.NUMERIC,
                Label: 'Fribeløp',
                Classes: 'half-width',
                ReadOnly: !!this.selectedSubEntity?.ID,
            },
            <UniFieldLayout>{
                Property: 'AgaRule',
                FieldType: FieldType.AUTOCOMPLETE,
                Label: 'Beregningsregel aga',
                Options: {
                    template: (obj) => (obj && obj.Sector ? `${obj.Sector}` : ''),
                    getDefaultData: () =>
                        this.selectedSubEntity.AgaRule
                            ? this.get(
                                  `model=AGASector&select=SectorID as SectorID,Sector as Sector&filter=SectorID eq ${this.selectedSubEntity.AgaRule}&top=1`,
                              )
                            : of(),
                    search: (query: string) =>
                        this.get(
                            `model=AGASector&select=SectorID as SectorID,Sector as Sector&filter=contains(Sector,'${query}') and year(ValidFrom) le activeyear()&top=50`,
                        ),
                    displayProperty: 'SectorID',
                    valueProperty: 'SectorID',
                    debounceTime: 200,
                },
                ReadOnly: !!this.selectedSubEntity?.ID,
            },
        ];
    }
}
