<h2>Skattefri organisasjon</h2>
<section class="alert info">
    <i class="material-icons">info_outline</i>
    <span>
        <p>Din organisasjon er registrert som skattefri. <br />Les mer om Skattefrie organisasjoner her:</p>
        <ul>
            <li>
                <a
                    href="https://www.skatteetaten.no/bedrift-og-organisasjon/arbeidsgiver/a-meldingen/veiledning/spesielle-grupper/skattefrie-organisasjoner/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Skattefrie organisasjoner</a
                >
            </li>
            <li>
                <a
                    href="https://www.skatteetaten.no/bedrift-og-organisasjon/rapportering-og-bransjer/bransjer-med-egne-regler/frivillige-og-ideelle-organisasjoner/skatt-for/skattefritak-for-organisasjoner/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Skattefritak for organisasjoner</a
                >
            </li>
        </ul>
    </span>
</section>

<p>For skattefrie foretak sett på krysset her. Det forenkler rapportering i a-meldingen.</p>

<rig-checkbox *ngIf="companySalary" [(ngModel)]="companySalary.Base_TaxFreeOrganization" (change)="hasChanges = true">
    Skattefri organisasjon
</rig-checkbox>
<rig-wizard-footer>
    <button class="secondary pull-left" (click)="navigate(0)" [disabled]="busy">Avbryt</button>
    <button class="c2a secondary" (click)="navigate(-1)" [disabled]="busy">Tilbake</button>
    <button class="c2a" *ngIf="!hasChanges" (click)="navigate(1)" [disabled]="busy">Neste</button>
    <button class="c2a" *ngIf="hasChanges" (click)="save()" [disabled]="busy">Lagre</button>
</rig-wizard-footer>
