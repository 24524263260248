<h2>Altinn systempålogging</h2>
<p>
    Har du ikke ID eller er usikker på om systemet er satt opp mot Altinn,
    <a href="https://www.altinn.no/ui/profile" target="_blank"> trykk her </a>
</p>

<section class="altinn-content">
    <altinn-settings-component></altinn-settings-component>
</section>

<rig-wizard-footer>
    <button class="secondary pull-left" (click)="cancel.emit()">Avbryt</button>
    <button class="c2a secondary" (click)="step.emit(-1)">Tilbake</button>
    <button class="c2a" (click)="step.emit(1)">Neste</button>
</rig-wizard-footer>
