import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IModalOptions, IUniModal } from '@uni-framework/uni-modal/interfaces';
import { WizardStep } from '@uni-framework/ui/design-system/wizard/wizard';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { SubEntitySettingsComponent } from './sub-entity-settings/sub-entity-settings.component';
import { CompanySettings, SubEntity } from '@uni-entities';
import { AltinnSalarySettingsComponent } from './altinn-salary-settings/altinn-salary-settings.component';
import { SalarySettingsSummaryComponent } from './salary-settings-summary/salary-settings-summary.component';
import { VacationPaySettingsComponent } from './vacation-pay-settings/vacation-pay-settings.component';
import { SalaryBankSettingsComponent } from './salary-bank-settings/salary-bank-settings.component';
import { SalaryAccountsSettingsComponent } from './salary-accounts-settings/salary-accounts-settings.component';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { Observable, of } from 'rxjs';
import { TaxFreeOrganizationSettingsComponent } from './tax-free-organization-settings/tax-free-organization-settings.component';
import { HttpClient } from '@angular/common/http';
import { CompanySettingsService } from '@app/services/common/companySettingsService';
import { switchMap } from 'rxjs/operators';
import { ModulusService } from '@app/services/common/modulusService';
import { StatisticsService } from '@app/services/common/statisticsService';

@Component({
    selector: 'uni-salary-settings-wizard-modal',
    templateUrl: './salary-settings-wizard-modal.html',
    styleUrls: ['./salary-settings-wizard-modal.sass'],
    imports: [
        DesignSystemModule,
        CommonModule,
        SubEntitySettingsComponent,
        AltinnSalarySettingsComponent,
        SalarySettingsSummaryComponent,
        VacationPaySettingsComponent,
        SalaryBankSettingsComponent,
        SalaryAccountsSettingsComponent,
        UniFrameworkModule,
        TaxFreeOrganizationSettingsComponent,
    ],
    standalone: true,
})
export class SalarySettingsWizardModal implements IUniModal, OnInit {
    @Input() options: IModalOptions;
    @Output() onClose: EventEmitter<boolean> = new EventEmitter();

    subEntities: SubEntity[] = [];
    steps: WizardStep[] = [];
    currentStep: WizardStep;
    isValidOrgNr: boolean = true;
    busy: boolean;
    private isTaxFreeOrganization: boolean;

    constructor(
        private router: Router,
        private httpClient: HttpClient,
        private companySettingsService: CompanySettingsService,
        private modulusService: ModulusService,
        private statisticsService: StatisticsService,
    ) {}

    ngOnInit(): void {
        this.busy = true;
        this.statisticsService
            .GetAllUnwrapped('model=PayrollRun&select=count(ID) as count')
            .pipe(
                switchMap((res) => (!res[0].count ? this.companySettingsService.getCompanySettings() : of())),
                switchMap((settings: CompanySettings) => {
                    // 15 - Forening, 38 - Stiftelse
                    this.isTaxFreeOrganization = settings.CompanyTypeID === 15 || settings.CompanyTypeID === 38;
                    if (!this.modulusService.isValidOrgNr(settings.OrganizationNumber?.replace(' ', ''))) {
                        this.isValidOrgNr = false;
                        return of();
                    } else if (!this.isTaxFreeOrganization) {
                        return this.httpClient.get(
                            `https://data.brreg.no/enhetsregisteret/api/enheter/${settings.OrganizationNumber}`,
                        );
                    }
                    return of();
                }),
            )
            .subscribe({
                next: (org: any) => {
                    const code = org.naeringskode1.kode.split('.')[0];
                    // 93 - Sport og fritid, 94 - Aktivitet i medlemsorganisasjoner, 99 - Internasjonale organisasjoner.
                    if (code === '93' || code === '94' || code === '99') {
                        this.isTaxFreeOrganization = true;
                    }
                },
                error: () => (this.isValidOrgNr = false),
            })
            .add(() => {
                this.setSteps();
                this.busy = false;
            });
    }

    canDeactivate?: () => boolean | Observable<boolean>;

    step(step: number): void {
        this.currentStep = this.steps[this.steps.indexOf(this.currentStep) + step];
    }

    cancel(): void {
        this.router.navigate(['settings', 'aga-and-subentities']).then(() => this.onClose.emit(false));
    }

    navigateToEmployees(): void {
        this.onClose.emit(true);
    }

    forceCloseValueResolver() {
        return false;
    }

    navigateToCompanySettings(): void {
        this.router.navigate(['settings', 'company']).then(() => this.onClose.emit(true));
    }

    private setSteps(): void {
        this.steps = [
            { label: 'Kom i gang med lønn', value: 0 },
            ...(this.isTaxFreeOrganization ? [{ label: 'Skattefri organisasjon', value: 1 }] : []),
            { label: 'Underenhet', value: 2 },
            { label: 'Feriepenger', value: 3 },
            { label: 'Bank', value: 4 },
            { label: 'Bokføringskontoer', value: 5 },
            { label: 'Altinn systempålogging', value: 6 },
            { label: 'Lønnsinnstillinger', value: 7 },
        ];

        this.currentStep = this.steps[0];
    }
}
