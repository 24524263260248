<h2>Bankinstillinger</h2>
<p>Du må ha en bankkonto og en skattetrekkskonto for å betale lønn.</p>

<uni-form
    *ngIf="fields"
    [config]="{ showLabelAbove: true }"
    [fields]="fields"
    [model]="settings"
    (changeEvent)="change($event)"
>
</uni-form>

<rig-wizard-footer>
    <button class="secondary pull-left" (click)="close()" [disabled]="busy">Avbryt</button>
    <button class="c2a secondary" (click)="navigate(-1)" [disabled]="busy">Tilbake</button>
    <button *ngIf="!isDirty" class="c2a" (click)="navigate(1)" [disabled]="busy">Neste</button>
    <button *ngIf="isDirty" class="c2a" (click)="saveSettings()" [disabled]="busy">Lagre</button>
</rig-wizard-footer>
