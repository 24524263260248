<h2>Feriepenger</h2>
<p>
    Ferieloven gir den ansatte rett til minst 10,2% feriepenger. De aller fleste bedrifter har utvidet denne retten til
    12%. Vi har satt 12% som standard, dette kan endres ved behov. Det er også mulig å sette en annen feriepengesats
    direkte på ansatt. Det er automatisk lagt på 2,3% på de over 60 år.
</p>

<section>
    <uni-form
        [config]="{ showLabelAbove: true }"
        [fields]="fields"
        [model]="vacationPaySettings"
        (changeEvent)="change($event)"
    >
    </uni-form>
</section>

<rig-wizard-footer>
    <button class="secondary pull-left" (click)="navigate(0)" [disabled]="busy">Avbryt</button>
    <button class="c2a secondary" (click)="navigate(-1)" [disabled]="busy">Tilbake</button>
    <button class="c2a" *ngIf="!hasChanges" (click)="navigate(1)">Neste</button>
    <button class="c2a" *ngIf="hasChanges" (click)="save()" [disabled]="busy">Lagre</button>
</rig-wizard-footer>
