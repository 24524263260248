import { Component, EventEmitter, OnInit, Output, SimpleChanges, ViewChild, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesignSystemModule } from '@uni-framework/ui/design-system/design-system.module';
import { FieldType, UniFieldLayout, UniFormModule } from '@uni-framework/ui/uniform';
import { UniFrameworkModule } from '@uni-framework/frameworkModule';
import { Observable, forkJoin } from 'rxjs';
import { ConfirmActions, UniModalService } from '@uni-framework/uni-modal';
import {
    CompanyVacationRateService,
    IVacationPaySettings,
} from '@app/services/salary/companySalary/companyVacationRateService';
import { CompanySalary, CompanyVacationRate, WageDeductionDueToHolidayType } from '@uni-entities';
import { CompanySalaryService } from '@app/services/salary/companySalary/companySalaryService';
import { FinancialYearService } from '@app/services/accounting/financialYearService';
import { ErrorService } from '@app/services/common/errorService';
import { VacationpayLineService } from '@app/services/salary/payrollRun/vacationpayLineService';

@Component({
    selector: 'uni-vacation-pay-settings',
    standalone: true,
    imports: [CommonModule, UniFormModule, DesignSystemModule, UniFrameworkModule],
    templateUrl: './vacation-pay-settings.component.html',
    styleUrls: ['./vacation-pay-settings.component.sass'],
})
export class VacationPaySettingsComponent implements OnInit {
    @Output() step: EventEmitter<number> = new EventEmitter();
    @Output() cancel: EventEmitter<boolean> = new EventEmitter();

    public vacationPaySettings: IVacationPaySettings;
    financialYear: number;
    fields: UniFieldLayout[] = [];
    hasChanges: boolean;
    busy: boolean;
    canEditRates: boolean;
    companySalary: CompanySalary;
    companyVacationRate: CompanyVacationRate;

    wageDeductionDueToHolidayList: any[] = [];

    constructor(
        private vacationPayLineService: VacationpayLineService,
        private companySalaryService: CompanySalaryService,
        private companyVacationRateService: CompanyVacationRateService,
        private financialYearService: FinancialYearService,
        private modalService: UniModalService,
        private errorService: ErrorService,
    ) {}

    ngOnInit(): void {
        this.getSettings();
    }

    change(event: SimpleChanges) {
        this.hasChanges = true;
        if (event['vacationWeeks']) {
            this.setVacationWeekValues(event['vacationWeeks'].currentValue);
        }
        if (event['rate']) {
            this.vacationPaySettings.rate = event['rate'].currentValue;
        }
        if (event['wageDeductionDueToHoliday']) {
            this.vacationPaySettings.wageDeductionDueToHoliday = event['wageDeductionDueToHoliday'].currentValue;
        }
        this.vacationPaySettings = { ...this.vacationPaySettings };
    }

    save() {
        this.busy = true;
        this.companySalary.WageDeductionDueToHoliday = this.vacationPaySettings.wageDeductionDueToHoliday;
        this.companyVacationRate.Rate = this.vacationPaySettings.rate;
        this.companyVacationRate.Rate60 = this.vacationPaySettings.rate60;

        forkJoin([
            this.companySalaryService.Put(this.companySalary.ID, this.companySalary),
            this.companyVacationRate.ID > 0
                ? this.companyVacationRateService.Put(this.companyVacationRate.ID, this.companyVacationRate)
                : this.companyVacationRateService.Post(this.companyVacationRate),
        ])
            .subscribe({
                next: () => (this.hasChanges = false),
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    navigate(step: number): void {
        if (this.hasChanges) {
            this.openUnsavedChangesModal().subscribe({
                next: (action: ConfirmActions) => {
                    if (action === ConfirmActions.ACCEPT) {
                        if (step == 0) {
                            this.cancel.emit();
                        }
                        this.step.emit(step);
                    }
                },
            });
        } else {
            if (step == 0) {
                this.cancel.emit();
            }
            this.step.emit(step);
        }
    }

    private openUnsavedChangesModal(): Observable<ConfirmActions> {
        return this.modalService.confirm({
            header: 'Ulagrede endringer',
            message: 'Du har endringer som ikke er lagret, disse vil bli forkastet.',
            buttonLabels: {
                accept: 'Forkast endringer og fortsett',
                cancel: 'Avbryt',
            },
        }).onClose;
    }

    private setVacationWeekValues(vacationWeeks: number) {
        if (vacationWeeks == 5) {
            this.vacationPaySettings.rate = 12;
            this.vacationPaySettings.rate60 = 2.3;
            this.vacationPaySettings.wageDeductionDueToHoliday = WageDeductionDueToHolidayType.Deduct4PartsOf26;
        } else if (vacationWeeks == 4) {
            this.vacationPaySettings.rate = 10.2;
            this.vacationPaySettings.rate60 = 2.3;
            this.vacationPaySettings.wageDeductionDueToHoliday = WageDeductionDueToHolidayType.Add1PartOf26;
        }
    }

    private getSettings() {
        this.busy = true;
        this.financialYear = this.financialYearService.getActiveYear();
        forkJoin([
            this.companySalaryService.getCompanySalary(),
            this.companyVacationRateService.getCurrentRates(new Date().getFullYear() - 1),
            this.companyVacationRateService.getCurrentRates(new Date().getFullYear()),
        ])
            .subscribe({
                next: ([companySalary, vacationRatePrevYear, vacationRate]: [
                    CompanySalary,
                    CompanyVacationRate,
                    CompanyVacationRate,
                ]) => {
                    this.companySalary = companySalary;
                    this.companyVacationRate = vacationRate.ID == 0 ? vacationRatePrevYear : vacationRate; //Can use previous year if no Id exists
                    this.wageDeductionDueToHolidayList = this.vacationPayLineService.getWageDeductionDueToHoliday(
                        this.companySalary.HasTwoLineVacationPay,
                    );
                    this.vacationPaySettings = {
                        rate: this.companyVacationRate.Rate,
                        rate60: this.companyVacationRate.Rate60,
                        wageDeductionDueToHoliday: companySalary.WageDeductionDueToHoliday,
                    };
                    if (this.companyVacationRate.ID == 0) {
                        //Default values
                        this.hasChanges = true;
                        this.vacationPaySettings.vacationWeeks = 5;
                        this.setVacationWeekValues(this.vacationPaySettings.vacationWeeks);
                    } else {
                        if (
                            this.companyVacationRate.Rate == 12 &&
                            companySalary.WageDeductionDueToHoliday == WageDeductionDueToHolidayType.Deduct4PartsOf26
                        ) {
                            this.vacationPaySettings.vacationWeeks = 5;
                        }
                        if (
                            this.companyVacationRate.Rate == 10.2 &&
                            companySalary.WageDeductionDueToHoliday == WageDeductionDueToHolidayType.Add1PartOf26
                        ) {
                            this.vacationPaySettings.vacationWeeks = 4;
                        }
                    }
                    this.setFormLayout();
                },
                error: (error) => this.errorService.handle(error),
            })
            .add(() => (this.busy = false));
    }

    private setFormLayout(): void {
        this.fields = [
            <UniFieldLayout>{
                Property: 'vacationWeeks',
                FieldType: FieldType.DROPDOWN,
                Label: 'Antall ferieuker',
                Legend: 'Kontaktinfo',
                Options: {
                    hideDeleteButton: true,
                    searchable: false,
                    valueProperty: 'value',
                    displayProperty: 'label',
                    source: [
                        { label: '5 uker', value: 5 },
                        { label: '4 uker', value: 4 },
                    ],
                },
                Required: true,
                Tooltip: {
                    Text: 'Etter ferieloven har en arbeidstaker rett på 4 uker og 1 dag ferie. Mange arbeidstakere har en tariffestet femte ferieuke.',
                },
            },
            <UniFieldLayout>{
                Property: 'rate',
                Label: 'Standard feriepengesats',
                FieldType: FieldType.NUMERIC,
                Required: true,
                Options: {
                    decimalLength: 2,
                    decimalSeparator: ',',
                    thousandSeparator: ' ',
                },
                ReadOnly: !this.canEditRates,
                Tooltip: {
                    Text: 'Prosentvis andel av et gitt lønnsgrunnlag som skal utbetales som feriepenger. Etter ferieloven har en arbeidstaker rett på 10,2 % feriepenger. Mange arbeidstakere har tariffestet 12 % feriepenger. Dersom du har angitt forskjellig feriepengesats per ansatt vil det overstyre selskapets standard sats som er oppgitt her',
                },
            },
            <UniFieldLayout>{
                Property: 'wageDeductionDueToHoliday',
                FieldType: FieldType.DROPDOWN,
                Options: {
                    source: this.wageDeductionDueToHolidayList,
                    valueProperty: 'id',
                    template: (item) => item.name,
                    subTextTemplate: (item) => item.description,
                    hideDeleteButton: true,
                },
                Label: 'Beregning av trekk i fastlønn for ferie',
                ReadOnly: !this.canEditRates,
                Tooltip: {
                    Alignment: 'left',
                    Text:
                        'Ferielovens minstekrav er trekk ved beregningen 25/26 (1/26), mens tariffestet trekk beregnes etter 30/26 (-4/26). ' +
                        'I følge arbeidsmiljøloven er lørdag en del av arbeidsuken. ' +
                        'Dersom selskapet er unntatt fra 6 dagers arbeidsuke benyttes beregningene der 5 dagers arbeidsuke er oppgitt som grunnlag.',
                },
            },
            <UniFieldLayout>{
                FieldType: FieldType.BUTTON,
                Label: 'Åpne felter for redigering',
                Options: {
                    class: 'link',
                    click: () => {
                        this.canEditRates = true;
                        this.setFormLayout();
                    },
                },
                Hidden: this.canEditRates,
            },
        ];
    }
}
