<h2>Gratulerer, du har fullført grunninnstillinger for lønn!</h2>

<section class="flex wizard-content">
    <p>
        Du har nå fullført alle nødvendige innstillinger for å kunne opprette lønn til dine ansatte. Vi håper at denne
        prosessen har vært enkel og oversiktlig for deg. Nå kan du begynne å opprette lønninger og administrere
        utbetalinger til dine ansatte.
        <br /><br />
        Husk at du alltid kan gå tilbake til innstillingsmenyen for å gjøre endringer eller justeringer ved behov.
        <br /><br />
        Vi ønsker deg lykke til med å administrere lønn for dine ansatte, og takk for at du valgte vår løsning.
    </p>

    <uni-illustration name="finish_line"></uni-illustration>
</section>

<rig-wizard-footer>
    <button class="c2a secondary" (click)="step.emit(-1)">Tilbake</button>
    <button class="c2a" (click)="navigateToEmployee()" [disabled]="busy">Fullfør</button>
</rig-wizard-footer>
