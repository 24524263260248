<h2>Underenheter</h2>
<section class="sub-entity-info" *ngIf="!subEntities.length && !canCreateNewSubEntities">
    <ng-container *ngIf="companySettings?.OrganizationNumber">
        <p class="mt-4">
            Du trenger Underenheter for å sende inn a-melding med ansatte. Disse hentes direkte fra Brønnøysund.
        </p>
        <p>
            Har du ikke mulighet til å hente disse digital, fyll inn opplysninger om dine underenheter
            <a (click)="addSubEntity()">her.</a>
        </p>
    </ng-container>
    <ng-container *ngIf="!companySettings?.OrganizationNumber">
        <section class="alert info">
            <i class="material-icons">info_outline</i>
            <section>
                Organisasjonsnummer er påkrevd for å kunne hente underenheter fra brønnøysundregisterene. Du kan enkelt
                legge dette til i <a (click)="navigateToCompanySettings()">firmainnstillingene.</a>
            </section>
        </section>
    </ng-container>

    <uni-illustration name="building"></uni-illustration>
</section>
<section class="mt-1 flex" *ngIf="subEntities.length || canCreateNewSubEntities">
    <section class="sub-entities mr-2">
        <section class="sub-entity-list small-split-view-list">
            <section class="sub-entity-title flex justify-between">
                <span>Underenhet</span>
                <span>Orgnummer</span>
            </section>
            <ul #subEntityList>
                <li
                    *ngFor="let subEntity of subEntities; let idx = index"
                    [class.selected]="subEntity._guid === selectedSubEntity._guid"
                    [class.focus]="idx === focusIndex"
                    (click)="setSelectedSubEntity(subEntity)"
                >
                    <span [title]="subEntity?.BusinessRelationInfo?.Name ?? ''">{{
                        subEntity?.BusinessRelationInfo?.Name ?? '-'
                    }}</span>
                    <span>
                        {{ subEntity?.OrgNumber ?? '-' }}
                        <i
                            class="ml-1 material-icons-outlined"
                            (click)="remove(subEntity)"
                            *ngIf="canCreateNewSubEntities"
                        >
                            delete
                        </i>
                    </span>
                </li>
            </ul>
        </section>
        <section class="add-button mb-2" *ngIf="canCreateNewSubEntities">
            <button class="c2a secondary" (click)="addSubEntity()" [disabled]="hasNewUnsaved">
                + Legg til underenhet
            </button>
        </section>
    </section>
    <section class="sub-entity-details">
        <uni-form
            [config]="{ showLabelAbove: true }"
            [fields]="fields"
            [model]="selectedSubEntity"
            (changeEvent)="change($event)"
        ></uni-form>
        <p *ngIf="!!this.selectedSubEntity?.ID">
            Soner, fribeløp og bergensingsregel i AGA kan ikke redigeres på underenheter som er lagret i systemet. Disse
            innstillingene kan kun endres fra lønnsinnstillingene.
        </p>
    </section>
</section>
<rig-wizard-footer>
    <button class="secondary pull-left" (click)="close()" [disabled]="busy">Avbryt</button>
    <button class="c2a secondary" (click)="navigate(-1)" [disabled]="busy">Tilbake</button>
    <button class="c2a" *ngIf="isPensionCompany" (click)="navigate(1)" [disabled]="busy">Hopp over dette steget</button>
    <button
        class="c2a"
        *ngIf="subEntities.length === 0 && !busy"
        (click)="importSubEntities()"
        [disabled]="busy || !companySettings?.OrganizationNumber"
    >
        Hent fra brønnøysundregisterene
    </button>
    <button class="c2a" *ngIf="(!!subEntities.length && !hasChanges) || busy" (click)="navigate(1)" [disabled]="busy">
        Neste
    </button>
    <button class="c2a" *ngIf="!!subEntities.length && hasChanges && !busy" (click)="save()" [disabled]="busy">
        Lagre
    </button>
</rig-wizard-footer>
