<section role="dialog" class="uni-modal" [attr.aria-busy]="busy">
    <rig-wizard *ngIf="currentStep" [steps]="steps" [activeStepValue]="currentStep.value">
        <h1>Kom i gang med lønn</h1>
        <section *ngIf="currentStep.value === 0" class="step">
            <h2>Velkommen til grunninnstillinger for lønn</h2>
            <section class="mt-1 mb-4">
                <p>
                    Vi hjelper deg med å sette opp grunnleggende innstillinger som er nødvendige for å komme i gang med
                    lønn.
                </p>
                <p>
                    Noen av innstillingene er allerede på plass, se gjennom om det stemmer og trykk videre til neste
                    steg. Det er også mulig å hoppe over noen steg og fullføre veiviser. Du kan legge inn det som
                    mangler ved en senere anledning.
                </p>
                <section class="alert warn" *ngIf="!isValidOrgNr">
                    <i class="material-icons">info_outline</i>
                    <section>
                        Du må ha et gyldig organisasjonsnummer i firmainnstillinger for å komme videre. Du kan legge det
                        til <a (click)="navigateToCompanySettings()">her.</a>
                    </section>
                </section>
            </section>

            <uni-illustration name="start"></uni-illustration>

            <rig-wizard-footer>
                <button class="secondary pull-left" (click)="cancel()">Avbryt</button>
                <button class="c2a secondary" *ngIf="currentStep.value !== 0" (click)="step(-1)">Tilbake</button>
                <button class="c2a" (click)="step(1)" [disabled]="busy || !isValidOrgNr">Neste</button>
            </rig-wizard-footer>
        </section>
        <uni-tax-free-organization-settings
            *ngIf="currentStep.value === 1"
            (step)="step($event)"
            (cancel)="cancel()"
            class="step"
        ></uni-tax-free-organization-settings>
        <uni-sub-entity-settings
            *ngIf="currentStep.value === 2"
            (step)="step($event)"
            (cancel)="cancel()"
            class="step"
        ></uni-sub-entity-settings>
        <uni-vacation-pay-settings
            *ngIf="currentStep.value === 3"
            (step)="step($event)"
            (cancel)="cancel()"
            class="step"
        ></uni-vacation-pay-settings>
        <uni-salary-bank-settings
            *ngIf="currentStep.value === 4"
            (step)="step($event)"
            (cancel)="cancel()"
            class="step"
        ></uni-salary-bank-settings>
        <uni-salary-accounts-settings
            *ngIf="currentStep.value === 5"
            (step)="step($event)"
            (cancel)="cancel()"
            class="step"
        ></uni-salary-accounts-settings>
        <uni-altinn-salary-settings
            *ngIf="currentStep.value === 6"
            (step)="step($event)"
            (cancel)="cancel()"
            class="step"
        ></uni-altinn-salary-settings>
        <uni-salary-settings-summary
            *ngIf="currentStep.value === 7"
            (step)="step($event)"
            (cancel)="cancel()"
            (done)="navigateToEmployees()"
            class="step"
        ></uni-salary-settings-summary>
    </rig-wizard>
</section>
